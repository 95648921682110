const flashMessagesInit = () => {
  document.querySelectorAll(".flash-message").forEach((flashMessage, index) => {
    flashMessage.addEventListener("click", (event) => {
      flashMessage.classList.add("animate__animated", "animate__fadeOutUp");
    });

    flashMessage.addEventListener("animationend", (event) => {
      flashMessage.remove();
    });
  });
};

export default flashMessagesInit;
