import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import { start as activeStorageStart } from "@rails/activestorage";
import "channels";
import flashMessagesInit from "./flash_messages";

Rails.start();

Turbolinks.start();

activeStorageStart();

const completed = () => {
  flashMessagesInit();
};

if (document.readyState === "complete") {
  setTimeout(completed);
} else {
  document.addEventListener("DOMContentLoaded", completed, false);
}
